import React, { useEffect } from "react";
import HrImage from "../assets/teams/management/mik_hr.png";
import tabs from "../assets/images/tabs.svg";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import useNavbarEffect from "../hooks/useNavbarEffect";
import { CommonHeader } from "../components";

const jobsData = [
  {
    id: 3,
    title: "Partnership Manager",
    description:
      "Join Mereb Technologies in Addis Ababa as a Partnership Manager to drive strategic alliances and fuel our growth in the outsourced software development industry.",
    date: "9/17/2024",
  },

  {
    id: 4,
    title: "Sales and Marketing Manager",
    description:
      "Lead our sales and marketing initiatives at Mereb Technologies in Addis Ababa as a Sales and Marketing Manager, driving growth and enhancing our brand in the outsourced software development industry.",
    date: "9/17/2024",
  },
  {
    id: 5,
    title: "Business Development Manager",
    description:
      "Join Mereb Technologies in Addis Ababa as a Business Development Manager to drive innovation and expand our global footprint by forging new partnerships in cutting-edge software development and BPO services.",
    date: "9/17/2024",
  },
  {
    id: 1,
    title: "Game Designer",
    description:
      "We are looking for a game designer to join our team. You will be responsible for creating engaging game concepts, developing detailed concept art, and animating characters and environments",
    date: "9/3/2024",
  },
  {
    id: 2,
    title: "Proposal Writer",
    description:
      "We are looking for a proposal writer to join our team. You will be responsible for crafting compelling proposals that highlight our software’s impact on business efficiency and digital transformation in the BPO sector",
    date: "9/3/2024",
  },

  {
    id: 6,
    title: "Frontend Developer",
    description:
      "We are looking for a frontend developer to join our team. You will be responsible for developing and maintaining our web applications.",
    date: "11/11/2023",
  },
  {
    id: 7,
    title: "Backend Developer",
    description:
      "We are looking for a backend developer to join our team. You will be responsible for developing and maintaining our server-side applications.",
    date: "11/11/2023",
  },
  {
    id: 8,
    title: "Full Stack Developer",
    description:
      "We are looking for a full stack developer to join our team. You will be responsible for developing and maintaining both frontend and backend applications.",
    date: "11/11/2023",
  },
];

function JobApplicationPage() {
  useNavbarEffect(true, false);

  function formatTimeDifference(pastDate, currentDate) {
    const diffInSeconds = Math.floor((currentDate - pastDate) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} second${diffInSeconds !== 1 ? "s" : ""} ago`;
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes !== 1 ? "s" : ""} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
    } else if (diffInDays < 30) {
      return `${diffInDays} day${diffInDays !== 1 ? "s" : ""} ago`;
    } else {
      const diffInMonths = Math.floor(diffInDays / 30);
      return `${diffInMonths} month${diffInMonths !== 1 ? "s" : ""} ago`;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CommonHeader
      title={"Job Applications"}
      subTitle={"Join the most innovative and creative team"}
      src={tabs}
      alt={"Tabs illustration"}
    >
      <div className="">
        <div className="container mx-auto px-4 py-16">
          <div className="flex flex-col md:flex-row items-start mb-16 bg-white p-6 rounded-lg ">
            <div className="md:w-2/3 p-4">
              <h2 className="text-4xl font-bold mb-4">
                Why join{" "}
                <span className="text-primaryMedium">Mereb Technologies</span>?
              </h2>
              <ul className="list-disc pl-5 space-y-4 text-gray-700">
                <li>
                  <strong>Innovative Projects:</strong> Be a part of
                  groundbreaking projects that challenge the status quo and push
                  the boundaries of technology. At Mereb, your creativity and
                  expertise will be at the forefront of every project.
                </li>
                <li>
                  <strong>Inclusive Culture:</strong> We believe in a workplace
                  where everyone feels valued and respected. Our inclusive
                  culture promotes diversity, encourages open communication, and
                  ensures everyone has a voice.
                </li>
                <li>
                  <strong>Continuous Learning:</strong> We invest in your
                  growth. With access to the latest tools, training programs,
                  and mentorship, you'll continuously evolve as a professional.
                </li>
                <li>
                  <strong>Global Impact:</strong> Join a team that works on
                  solutions with a global reach. Your work at Mereb will impact
                  clients and communities around the world.
                </li>
                <li>
                  <strong>Work-Life Harmony:</strong> We offer flexible working
                  arrangements that allow you to balance your professional
                  aspirations with your personal life. Enjoy the freedom to
                  thrive in all aspects of life.
                </li>
              </ul>
            </div>

            <div className="md:w-[30%] p-6 flex flex-col items-center bg-gradient-to-br from-white to-gray-100 border border-gray-200 rounded-1xl shadow-sm hover:shadow-1xl transform transition-all duration-300">
              <div className="relative w-48 h-48 mb-4">
                <img
                  src={HrImage}
                  alt="HR Contact"
                  className="w-full h-full rounded-full object-contain border-4 border-white shadow-md"
                />
                <div className="absolute inset-0 bg-black bg-opacity-10 rounded-full"></div>
              </div>
              <div className="text-center">
                <h3 className="text-2xl font-bold text-gray-800 mb-2 tracking-wide">
                  Mickiyas Kinfemichael
                </h3>
                <p className="text-lg font-medium text-gray-500 mb-4">
                  Recruiter
                </p>
                <div className="text-lg text-gray-600 space-y-2 text-left">
                  <p>
                    <MdEmail className="inline-block mr-2 text-blue-900" />
                    <a
                      href="mailto:hr@merebtechnologies.com"
                      className="text-blue-900 hover:text-blue-700 transition-colors duration-200"
                    >
                      career@mereb.tech
                    </a>
                  </p>
                  <a href="tel:+251988259995">
                    <FaPhoneAlt className="inline-block mr-2 text-blue-900" />
                    <span className="text-gray-600">+251988259995</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {jobsData.map((job) => (
              <div
                key={job.id}
                className="bg-gradient-to-r from-primary to-primaryMedium text-white p-6 rounded-lg shadow-xl transform transition-all hover:scale-105 hover:shadow-2xl flex flex-col h-full"
              >
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-2xl font-extrabold tracking-wide">
                    {job.title}
                  </h3>
                  <span className="inline-block px-3 whitespace-nowrap py-1 bg-white text-indigo-500 text-xs font-semibold rounded-full shadow-sm">
                    Full-Time
                  </span>
                </div>
                <p className="text-gray-100 mb-6 leading-relaxed flex-grow">
                  {job.description}
                </p>
                <div className="flex items-center justify-between mt-auto">
                  <Link
                    to={`/jobs/${job.id}`}
                    state={{ title: job.title }}
                    className="bg-white text-gray-900 px-5 py-2 rounded-full font-semibold shadow-md hover:bg-primary hover:text-white transition-all transform hover:-translate-y-1"
                  >
                    Apply Now
                  </Link>
                  <span className="text-sm italic opacity-75">
                    {formatTimeDifference(new Date(job.date), new Date())}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </CommonHeader>
  );
}

export default JobApplicationPage;
